import { Licence } from '@club-admin-services/membership.service';
import { LinkedMemberInfo } from '../club-members/src/app/services/booking.service';
import { UserRole } from './services/user.service';
import { TimeslotSPData } from '@sportinsight/src/app/sub-projects/club/src/app/services/booking.service';

export type GenericAPIResponse<T> = {
  data: T;
  message: string;
  status: number;
};

export type APIResponse = GenericAPIResponse<any>;
export type VoidAPIResponse = GenericAPIResponse<void>;

export type SearchAPIResponse<T> = GenericAPIResponse<{ search: T }>;

export interface CheckinApiResponse extends GenericAPIResponse<any> {
  data: {
    canBeCheckedIn: boolean;
    checkInEnabled: boolean;
    failedDueMeters: boolean;
    failedDueMinutes: boolean;
    message: string;
    metersDiff: number;
    minutesDiff: number;
    result: boolean;
  };
}

export type User = {
  firstName: string;
  lastName?: string;
  displayName?: string;
  title?: string;
  statusId?: number;
  userId: number;
  userTypeId?: number;
  email?: string;
  workEmail?: string;
  phone?: string;
  website?: string;
  id?: number;
  type?: string;
  organisation?: string;
  accountId?: number;
  roleTitle?: string;
  relationshipRoleLabels?: string;
  lastActivity?: any;
  name?: string;
  roleLabels?: string;
  roles?: string[];
  user_roles?: UserRole[];
  sports?: string;
  urlType?: string;
  totalRows?: number;
  isKeyRelationship?: boolean;
  relationshipStatus?: number;
  membershipLabel?: string;
  membershipTag?: string;
  licenceProductName?: string;
  licences?: Licence[];
  siteId: number;
  activityPreference?: ActivityData;
  sportPreferenceId?: number;
  permissions?: string[];
  membershipBaseInfo?: { renewalDate: string; barCardNo: string };
  linkedMemberInfo: LinkedMemberInfo[];
  canBook?: number;
  role?: string;
  checkedIn?: number | boolean;
  squashLevel?: number | null;
  squashPlayerId?: number | null;
};

export type SidebarItem = {
  id: string;
  name: string;
  class: string;
  link: string;
  disabled: boolean;
};

export type Entity = {
  entityType: string;
  entityId: number;
  name: string;
  types: string;
  statusId: number;
  urlType?: string;
  totalRows?: number;
  workPhone?: number;
  homePhone?: number;
  mobilePhone?: number;
  email?: string;
};

export type Site = {
  siteId: number;
  siteName: string;
  siteLabel: string;
  urlTag: string;
  clubName: string;
  logoURL?: string;
  siteTypeId: number;
  adminSiteId: number;
  checkInBeforeMins: number;
  checkInAfterMins: number;
  checkInMeters: number;
  latitude: number;
  longitude: number;
  defaultMembershipExpiryDate: string;
  bookingReminderPeriod: number;
  checkInEnabled: number;
  type: SiteType;
  squashLevelURL?: string;
};

export type SiteType = {
  siteTypeId: number;
  siteTypeTag: string;
  siteTypeLabel: string;
};

export type Address = {
  addressId: number;
  displayAddress?: string;
  addressName: string;
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  addressLine4?: string;
  city: string;
  county?: string;
  postcode: string;
  country?: string;
  urlType?: string;
  facilities?: Facility[];
  activities?: number[];
  totalRows?: number;
  isPrimary?: 0 | 1;
  isVenue?: 0 | 1;
};

export type CoachingVenue = Address;

export type Facility = {
  addressFacilityId: number;
  addressId?: number;
  addressName?: string;
  facilityId: number;
  number?: number;
  notes?: string;
  facilityTag: string;
  facilityLabel: string;
  totalRows?: number;
};

export type Column = {
  id: string;
  name: string;
  filter?: FilterItem;
  hideSortBy?: boolean;
  tooltipContent?: string;
  menu?: ColumnMenuItem[];
};

export type ColumnMenuItem = {
  label: Function; // Function in case dynamic label is needed
  class: string;
  onClick: Function;
  onClickType: string;
};

export type TabItem = {
  id: string | number;
  name: string;
  columns?: Column[];
};

export type ActivityData = {
  coachingVenueActivityId?: number;
  activityId: number;
  activityLabel: string;
  facilityTypeId?: number;
  qualifications?: Qualification[];
  totalRows?: number;
};

export type Role = {
  roleId?: string;
  roleTag?: string;
  roleLabel?: string;
  localities?: string;
  roleStatusId?: number;
  unitaryAuthorities?: UnitaryAuthority[];
};

export type Organisation = {
  id: number;
  name: string;
  type: string;
  address?: string;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  addressLine4?: string;
  city?: string;
  county?: string;
  postcode?: string;
  phone?: number;
  relationshipRoleLabels?: string;
  roles?: string[];
  isPrimary?: boolean;
  statusId?: boolean;
  relationshipStatusId?: boolean;
  totalRows?: number;
  urlType?: string;
};

export type OrganisationType = {
  typeTag: string;
  typeName: string;
  subTypes?: OrganisationType[];
  count?: number;
};

export type Project = {
  projectId?: number;
  name?: string;
  totalRows?: number;
  description?: string;
  status?: string;
  members?: string;
  startDate?: Date;
  endDate?: Date;
  displayStartDate: string;
  displayEndDate: string;
  projectRoles?: string[];
  relationshipRoleTags?: string[];
  projectRoleTags?: string[];
  projectRolesString?: string;
  roleTitle?: string;
  urlType?: string;
  isPrimary: boolean;
};

export type Event = {
  eventId?: number;
  name?: string;
  totalRows?: number;
  description?: string;
  status?: string;
  statusId?: number;
  startDate?: Date;
  endDate?: Date;
  displayStartDate?: string;
  displayEndDate?: string;
  eventRoles?: string[];
  relationshipRoleTags?: string[];
  eventRoleTags?: string[];
  eventRolesString?: string;
  roleTitle?: string;
  urlType?: string;
};

export type InteractionType = {
  interactionTypeTag: string;
  interactionType: string;
};

export type Tag = {
  entityId?: number;
  entityType?: string;
  name?: string;
  type?: string;
  subType?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
};

export type InteractionForm = {
  interactionId?: number;
  contactDetails: {
    userId?: number;
    firstName?: string;
    lastName?: string;
    email?: string;
  };
  organisationDetails?: {
    accountId?: number;
    name?: string;
    type?: string;
    subType?: string;
  };
  linkEntities?: boolean;
  roles?: string[];
  roleTitle?: string;
  about?: string;
  summary?: string;
  interactionType?: string;
  date?: Date | string;
  tagsExisting?: Tag[];
  tagsNew?: Tag[];
};

export type Interaction = {
  interactionId?: number;
  interactionType?: string;
  interactionTypeTag?: string;
  interactionDate?: Date;
  userId?: number;
  subject?: string;
  summary?: string;
  whoUserId?: string;
  whoFirstName?: string;
  whoLastName?: string;
  atAccountId?: string;
  atName?: string;
  tags?: Tag[];
  linkEntities?: boolean;
  relationshipRoles?: string[];
  relationshipRoleTitle?: string;
  createdByFirstName?: string;
  createdByLastName?: string;
  totalRows?: number;
  urlType?: string;
};

export type FilterOption = {
  optionTag: string;
  optionLabel: string;
  optionType?: string;
  optionTagShort?: string
};

export type FilterItem = {
  type: 'search' | 'checkbox' | 'input';
  options?: FilterOption[];
  value?: any[];
};

export type ColumnFilter = {
  columnName: string;
  filter: FilterItem;
};

export type PreviousVertical = {
  page: string;
  tab: string;
  searchTerm: string;
  filters: ColumnFilter[];
};

export type UserQualification = Qualification & {
  userQualificationId?: number;
  userId?: number;
  activityId: string;
  activityLabel: string;
  dateObtained?: Date;
  dateExpires?: Date;
  totalRows?: number;
};

export type Qualification = {
  qualificationId: number;
  qualificationLabel: string;
};

export type Note = {
  noteId: number;
  note: string;
  entityId: number;
  entityType: string;
  dateCreated: Date;
  userId?: number;
  firstName: string;
  lastName: string;
  rowNumber: number;
  totalRows: number;
};

export type Alert = {
  type: 'error' | 'success';
  text: string;
};

export type Ward = {
  name?: string;
  ward?: string;
  wardId?: number;
  id?: number;
  people?: number;
  organisations?: number;
  interactions?: number;
  events?: number;
  totalRows?: number;
  urlType?: string;
};

export type UnitaryAuthority = {
  unitaryAuthorityId: number;
  unitaryAuthority: string;
  id?: number;
  name?: string;
  people?: number;
  organisations?: number;
  interactions?: number;
  events?: number;
  totalRows?: number;
  urlType?: string;
};

export type Locality = UnitaryAuthority | Ward;

export type ProjectDate = {
  projectDateId: number;
  date: Date | string;
  description?: string;
  totalRows?: number;
  dateType?: string;
};

// Passwords must be at least 8 characters and contain one lower case letter (a-z), one upper case letter (A-Z) and one number (0-9)
export const passwordRegExp: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;

export const emailRegExp: RegExp = /^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,10}$/;

export const phoneNumberRegExp: RegExp = /^[- +()0-9]+$/;

export const textOnlyRegExp: RegExp = /^[-_a-zA-Z0-9]*$/;

export type Login = {
  activationCode: string;
  active: number;
  changePassword: number;
  dateCreated: string;
  dateLastSuccess: string;
  dateModified: string;
  locked: number;
  loginId: number;
  loginEmail: string;
  modifiedBy: number;
  siteId: number;
};

export type BookingType = {
  id: number;
  label: string;
  siteId: number;
  typeTag: string;
  parentId: number;
  facilityTypeId: number;
};

export type ContextMenuItem = {
  label: string;
  action: (...args: any[]) => any;
  classes?: string;
};

export const MY_DATE_FORMATS_STANDARD = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'Do MMM YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const MY_DATE_FORMATS_STANDARD_DAY_NO_YEAR = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'ddd Do MMM',
    monthYearLabel: 'MM',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM',
  },
};

export const MY_DATE_FORMATS_STANDARD_DAY = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'ddd Do MMM YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const blobOptions = {
  responseType: 'blob' as 'blob',
};

export type StandardColumn = {
  name: string;
  nameId: string;
};

export type ImageInfo = {
  url: string;
  caption: string;
};

export type ClubCheckinData = {
  siteId: number;
  checkInBeforeMins: number;
  checkInAfterMins: number;
  checkInEnabled: boolean;
  checkInMeters: number;
};


export type BookingInfoTooltipConfig = {
  bookingId: number;
  bookingTypeId: number;
  bookingLabel: string;
  isRepeatBooking: boolean;
  notes: string;
  maxAbility?: number;
  minAbility?: number;
  bookedBy?: string;
  checkedIn: boolean;
  linkedUsers?: LinkedUserInfo[];
};

export type LinkedUserInfo = {
  userId?: number;
  name: string;
  role?: string;
  membershipLabel?: string;
  owner: boolean;
  checkedIn: boolean;
};

export type RemovePersonConfig = {
  selectedTimeslotBookingId: number;
  userLinkedMember: boolean;
  userOwnsBooking: boolean;
  isRepeatOrAdminBooking: boolean;
};
