import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@shared/environment';
import { CardType } from '@shared/services/user.service';
import { ActivityData, BookingType, GenericAPIResponse, Site, User } from '@shared/definitions';
import { Card } from '@shared/services/user.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { NewsType } from './news.service';
import { Membership } from './membership.service';
import { DocumentType } from '@shared/services/documents.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardStateService {
  private readonly modalFreezeSubject = new BehaviorSubject<boolean>(false);
  private readonly currentSiteSubject = new BehaviorSubject<Site>(null);
  private readonly configLoadedSubject = new BehaviorSubject<boolean>(false);
  private readonly clubFacilityTypeSubject = new BehaviorSubject<ClubFacilityType[]>([]);
  private readonly membershipProductsSubject = new BehaviorSubject<Product[]>([]);
  private readonly paymentTypesSubject = new BehaviorSubject<PaymentType[]>([]);
  private readonly paymentTermsSubject = new BehaviorSubject<PaymentTerm[]>([]);
  private readonly titlesSubject = new BehaviorSubject<string[]>([]);
  private readonly gendersSubject = new BehaviorSubject<string[]>([]);
  private readonly activityListSubject = new BehaviorSubject<ActivityData[]>([]);
  private readonly membershipSourceSubject = new BehaviorSubject<MembershipSource[]>([]);
  private readonly commsPrefsSubject = new BehaviorSubject<CommsPrefType[]>([]);
  private readonly interestsSubject = new BehaviorSubject<Interest[]>([]);
  private readonly mediaUsageTypesSubject = new BehaviorSubject<MediaUsageType[]>([]);
  private readonly cardTypeSubject = new BehaviorSubject<CardType[]>([]);
  private readonly newsTypesSubject = new BehaviorSubject<NewsType[]>([]);
  private readonly documentTypesSubject = new BehaviorSubject<DocumentType[]>([]);
  private readonly facilityListSubject = new BehaviorSubject<Facility[]>([]);
  private readonly bookingTypeSubject = new BehaviorSubject<BookingType[]>([]);
  private readonly bookingSubTypeSubject = new BehaviorSubject<BookingType[]>([]);
  private readonly squashLevelPlayerURLSubject = new BehaviorSubject<string>('');

  public modalFreeze = this.modalFreezeSubject.asObservable();
  public currentSite = this.currentSiteSubject.asObservable();
  public configLoaded = this.configLoadedSubject.asObservable();
  public clubFacilityTypeList = this.clubFacilityTypeSubject.asObservable();
  public membershipProductList = this.membershipProductsSubject.asObservable();
  public paymentTypeList = this.paymentTypesSubject.asObservable();
  public paymentTermList = this.paymentTermsSubject.asObservable();
  public titlesList = this.titlesSubject.asObservable();
  public genderList = this.gendersSubject.asObservable();
  public activityList = this.activityListSubject.asObservable();
  public membershipSourceList = this.membershipSourceSubject.asObservable();
  public commsPrefList = this.commsPrefsSubject.asObservable();
  public interestList = this.interestsSubject.asObservable();
  public mediaUsageTypeList = this.mediaUsageTypesSubject.asObservable();
  public cardTypeList = this.cardTypeSubject.asObservable();
  public newsTypeList = this.newsTypesSubject.asObservable();
  public documentTypeList = this.documentTypesSubject.asObservable();
  public facilityList = this.facilityListSubject.asObservable();
  public bookingTypeList = this.bookingTypeSubject.asObservable();
  public bookingSubTypeList = this.bookingSubTypeSubject.asObservable();
  public displayNotificationCentre = false;
  public squashLevelPlayerURL = this.squashLevelPlayerURLSubject.asObservable();

  constructor(private readonly http: HttpClient) { }

  public setSquashLevelPlayerURL(squashPlayerURL: string): void {
    this.squashLevelPlayerURLSubject.next(squashPlayerURL);
  }

  public freezeModal(frozen: boolean): void {
    this.modalFreezeSubject.next(frozen);
  }

  public setCurrentSite(site: Site): void {
    this.currentSiteSubject.next(site);
  }

  public setConfigLoaded(value: boolean): void {
    this.configLoadedSubject.next(value);
  }

  public setClubFacilityTypeList(list: ClubFacilityType[]): void {
    this.clubFacilityTypeSubject.next(list);
  }

  public setMembershipProductList(list: Product[]): void {
    this.membershipProductsSubject.next(list);
  }

  public setPaymentTypeList(list: PaymentType[]): void {
    const noPaymentType: PaymentType = {
      id: null,
      name: 'N/A',
      tag: 'NA',
    };
    list.push(noPaymentType);
    this.paymentTypesSubject.next(list);
  }

  public setPaymentTermList(list: PaymentTerm[]): void {
    this.paymentTermsSubject.next(list);
  }

  public setTitlesList(list: string[]): void {
    this.titlesSubject.next(list);
  }

  public setGenderList(list: string[]): void {
    this.gendersSubject.next(list);
  }

  public setActivityList(list: ActivityData[]): void {
    this.activityListSubject.next(list);
  }

  public setMembershipSourceList(list: MembershipSource[]): void {
    this.membershipSourceSubject.next(list);
  }

  public setCommsPrefList(list: CommsPrefType[]): void {
    this.commsPrefsSubject.next(list);
  }

  public setInterestList(list: Interest[]): void {
    this.interestsSubject.next(list);
  }

  public setMediaUsageTypeList(list: MediaUsageType[]): void {
    this.mediaUsageTypesSubject.next(list);
  }

  public setCardTypeList(list: CardType[]): void {
    this.cardTypeSubject.next(list);
  }

  public setFacilityList(list: Facility[]): void {
    this.facilityListSubject.next(list);
  }

  public setBookingTypeList(list: BookingType[]): void {
    this.bookingTypeSubject.next(list);
  }

  public setBookingSubTypeList(list: BookingType[]): void {
    this.bookingSubTypeSubject.next(list);
  }

  public getDashboardConfig(): Observable<GenericAPIResponse<ConfigResponse>> {
    const url = `${environment.apiURL}/dashboard-state/config`;
    return this.http.get<GenericAPIResponse<ConfigResponse>>(url);
  }

  public getMemberDashboardInfo(): Observable<GenericAPIResponse<MemberDashboardInfo>> {
    const url = `${environment.apiURL}/dashboard-state/member-info`;
    return this.http.get<GenericAPIResponse<MemberDashboardInfo>>(url);
  }

  public setNewsTypeList(newsTypes: NewsType[]): void {
    this.newsTypesSubject.next(newsTypes);
  }

  public setDocumentTypeList(documentTypes: DocumentType[]): void {
    this.documentTypesSubject.next(documentTypes);
  }
}

export type MemberDashboardInfo = {
  cards: Card[];
  isAppUser: boolean;
  linkedGuests: User[];
  linkedMembers: User[];
  sportPreference: number;
  membership: {
    tag: string;
    label: string;
    renewalDate: string;
    paymentMethod: string;
  } | null;
  licenceDetails: Membership;
};

export type ConfigResponse = {
  clubFacilityTypes: ClubFacilityType[];
  site: Site;
  membershipProducts: Product[];
  paymentTypes: PaymentType[];
  paymentTerms: PaymentTerm[];
  titles: string[];
  genders: string[];
  activities: ActivityData[];
  membershipSources: MembershipSource[];
  commsPrefTypes: CommsPrefType[];
  interests: Interest[];
  mediaUsageTypes: MediaUsageType[];
  memberCardTypes: CardType[];
  newsTypes: NewsType[];
  documentTypes: DocumentType[];
  facilityList: Facility[];
  bookingTypes: BookingType[];
  bookingSubTypes: BookingType[];
  squashLevelPlayerURL: string;
};

export type ClubFacilityType = {
  groupLabel: string;
  groupTag: string;
  id: number;
  typeTag: string;
};

export type PaymentType = {
  id: number;
  name: string;
  tag: string;
};

export type PaymentTerm = {
  id: number;
  name: string;
  tag: string;
  siteId: number;
};

export type Product = {
  maxLinkedMembers: number;
  name: string;
  productId: number;
  productTag: string;
  productTypeId: number;
  siteId: number;
  standardPrice: number;
  statusId: number;
  voucherEligible?: number;
};

export type CommsPrefType = {
  communicationTypeId: number;
  communicationTypeTag: string;
  communicationTypeLabel: string;
};

export type Interest = {
  interestId: number;
  interestLabel: string;
};

export type MediaUsageType = {
  mediaUsageTypeId: number;
  mediaUsageTypeTag: string;
  mediaUsageTypeLabel: string;
};

export type MembershipSource = {
  membershipSourceId: number;
  membershipSourceLabel: string;
  membershipSourceTag: string;
};

export type Facility = {
  id: number;
  typeId: number;
  type: ClubFacilityType;
  label: string;
  siteId: number;
  orderIndex: number;
};
